import Rest from '@/services/Rest';

/**
 * @typedef {OrderService}
 */
export default class OrderService extends Rest {
  /**
   * @type {String}
   */
  static resource = '/order'
}
