import Rest from '@/services/Rest';

/**
 * @typedef {OrderServiceCancel}
 */
export default class OrderServiceCancel extends Rest {
    /**
     * @type {String}
     */
    static resource = '/order/cancel'
}
