<template>
  <div v-if="custom.user">
    <a
      href="#"
      v-b-toggle="'dados-usuario'"
      class="toggle-collapse"
      @click.prevent="show_user = !show_user"
      >{{ show_user ? "Ocultar" : "Visualizar" }} Dados do Usuário</a
    >
    <div id="dados-usuario" class="mt-2" v-show="show_user" data-anima="top">
      <div class="dados-pedido">
        <div class="item-info">
          <span>CPF</span>
          <p>
            {{ custom.user.cpf ? custom.user.cpf : custom.user.cnpj }}
          </p>
        </div>
        <div class="item-info">
          <span>NOME</span>
          <p>{{ custom.user.first_name }} {{ custom.user.last_name }}</p>
        </div>
        <div class="item-info">
          <span>TELEFONE</span>
          <p>
            +{{ custom.user.cellphone_area_code }} {{ custom.user.cellphone }}
          </p>
        </div>
        <div class="item-info">
          <span>E-MAIL</span>
          <p>{{ custom.user.email }}</p>
        </div>
        <div class="item-info">
          <span>EMPREENDIMENTO</span>
          <p>{{ custom.enterprise ? custom.enterprise.name : "" }}</p>
        </div>
        <div class="item-info">
          <span>UNIDADE</span>
          <p>UNIDADE {{ custom.unity.number }}</p>
        </div>

        <div class="item-info" v-if="custom.observation">
          <span>OBSERVAÇÕES</span>
          <p class="obs">{{ custom.observation }}</p>
        </div>
      </div>
      <div class="rodape-pedido">
        <div class="data-pedido">
          <div class="item-info">
            <span>DATA PEDIDO</span>
            <p>{{ custom.created_at | date }}</p>
          </div>
          <div class="item-info">
            <span>HORÁRIO</span>
            <p>{{ custom.created_at | hours }}</p>
          </div>
          <div class="item-info">
            <span>IP DE ACESSO</span>
            <p>---</p>
          </div>
        </div>

        <div class="termos">
          <p class="text-muted">
            <span class="text-dark">CLIENTE</span> está de acordo com os
            <a class="text-info" href="https://r4marketing.com.br/termos-de-uso" target="_blank"
              >Termos e Uso</a
            >
            e ciente da
            <a class="text-info"
              href="#"
              target="_blank"
              >Política de Privacidade.</a
            >
          </p>

          <BaseClientKitConditions :new_style="true" class="condicoes" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
export default {
  name: "CustomItemUser",
  data() {
    return {
      show_user: true,
      width: null,
    };
  },
  props: {
    custom: {
      type: Object,
      default: () => {},
    },
  },
  filters: {
    date(date) {
      return moment(date).format("DD/MM/YYYY");
    },
    hours(date) {
      return moment(date).format("HH:MM");
    },
  },
  computed: {
    isMobile() {
      return this.width <= 768;
    },
  },
  methods: {
    handleResize() {
      // padrão
      this.width = window.innerWidth;

      if (this.width <= 768) {
        this.show_user = true;
      }
    },
  },
  mounted() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
};
</script>

<style scoped>
.dados-pedido {
  background: rgba(244, 244, 246, 0.5);
  padding: 42px 35px;
  border-radius: 16px;
}
.dados-pedido span {
  font-size: 12px;
  color: #9e9e9a;
}
.dados-pedido p {
  font-size: 14px;
  color: rgba(47, 47, 47, 0.8);
  font-weight: 600;
}
.dados-pedido p.obs {
  font-size: 14px;
  font-weight: normal;
}
.rodape-pedido {
  padding: 20px 35px;
}
.rodape-pedido .data-pedido {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.data-pedido .item-info span,
.data-pedido .item-info p {
  color: rgba(0, 0, 0, 0.5);
  font-size: 11px;
  font-weight: 600;
}
.rodape-pedido .termos {
  margin: 20px auto;
}
.rodape-pedido .termos p {
  font-size: 14px;
  color: #787878;
  font-weight: 600;
}
.rodape-pedido .termos p span,
.rodape-pedido .termos p a {
  color: var(--color);
}
.toggle-collapse {
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(244, 244, 246, 0.5);
  font-weight: 600;
  padding: 10px;
  border-radius: 8px;
  display: none;
}
@media screen and (max-width: 768px) {
  .toggle-collapse {
    display: flex;
  }
  .dados-pedido {
    padding: 20px;
    border-radius: 8px;
  }
  .dados-pedido span {
    font-size: 11px;
  }
  .dados-pedido p {
    font-size: 14px;
  }
  .dados-pedido p.obs {
    font-size: 13px;
  }
  .rodape-pedido {
    padding: 20px 0;
  }
  .rodape-pedido .data-pedido {
    flex-wrap: wrap;
  }
  .rodape-pedido .termos p{
    font-size: 13px;
  }
}
</style>